import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from '../Icon';
import { MOBILE_BREAKPOINT } from '../../../constants';
import './styles.scss';

/**
 * NOTE: This component is used in two different repos.
 *
 * Change this flag as needed to control which URL shows in the dropdown.
 */
const isCanadaSite = true;

/**
 * Switches to a different country's domain name.
 */
const CountrySwitcher = () => {
  const [open, setOpen] = useState(false);
  const list = useRef(null);

  // This listener will close the Country Switcher if you switch into mobile
  // without closing the country switcher.
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < MOBILE_BREAKPOINT && open) {
        setOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open]);

  const getClassNames = () => {
    return classNames('language-switcher', open && 'language-switcher--open');
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getHeight = () => (open ? list.current.offsetHeight : '');

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={handleClick}
      className={getClassNames()}
    >
      <div className="language-switcher__inner" style={{ height: getHeight() }}>
        <ul className="language-switcher__list" ref={list}>
          <li
            className={`language-switcher--us language-switcher__item${
              !isCanadaSite ? ' language-switcher__item--is-active' : ''
            }`}
          >
            <a
              onClick={isCanadaSite ? null : (e) => e.preventDefault()}
              className="language-switcher__link"
              href="http://girls4tech.discoveryed.com/"
            >
              United States
            </a>
          </li>
          <li
            className={`language-switcher--ca language-switcher__item${
              isCanadaSite ? ' language-switcher__item--is-active' : ''
            }`}
          >
            <a
              onClick={isCanadaSite ? (e) => e.preventDefault() : null}
              className="language-switcher__link"
              href="http://girls4tech.discoveryed.ca/"
            >
              Canada
            </a>
          </li>
        </ul>
        <Icon
          name="caratdown"
          className={classNames(
            'language-switcher__arrow',
            open && 'language-switcher__arrow--rotated'
          )}
        />
      </div>
    </div>
  );
};

export default CountrySwitcher;
