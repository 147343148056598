import React from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { LANG_EN, LANG_FR } from '../../../constants';
import './styles.scss';

const LanguageToggle = () => {
  const { t, i18n } = useTranslation(['common']);
  const location = useLocation();

  const handleClick = () => {
    window.location.pathname = `/${
      i18n.language === LANG_FR ? LANG_EN : LANG_FR
    }${location.pathname.replace(/\/[a-z]{2}-[A-Z]{2}/, '')}`;
  };

  return (
    <button onClick={handleClick} className="lang-toggle">
      {i18n.language === LANG_FR ? 'English' : 'Français'}
    </button>
  );
};

export default LanguageToggle;
