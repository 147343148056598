import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox } from '../Form';

export default {
  email: {
    label: 'subscribe:email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'subscribe:kto3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: 'subscribe:4to5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: 'subscribe:6to8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: 'subscribe:9to12',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'subscribe:stem',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'subscribe:languageArts',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'subscribe:healthAndPhysicalEducation',
    component: Checkbox,
    checked: false,
  },
  interestedInVirtualFieldTrips: {
    label: 'subscribe:virtualFieldTrips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'subscribe:sweepstakes',
    component: Checkbox,
    checked: false,
  },
  subscribeToDE: {
    label: 'subscribe:deOptIn',
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: 'subscribe:partnerOptIn',
    component: Checkbox,
    checked: false,
  },
};
