import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import classNames from 'classnames';
import { Form, SubmitButton, Notice } from '../Form';
import Modal from '../Modal';
import { Row, Column } from '../Grid';
import schema from './schema';
import { Trans, useTranslation } from 'react-i18next';
import './styles.scss';
import OutboundLink from '../OutboundLink';

const Subscribe = ({ trigger, className, ...other }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            programName
            partnerPrivacyUrl
            subscribeFormId
          }
        }
      }
    `
  );
  const { t, i18n } = useTranslation(['subscribe']);

  const [isComplete, setIsComplete] = useState(false);
  const [errors, setErrors] = useState({});
  const [fieldValues, setFieldValues] = useState(
    Object.assign(
      {},
      ...Object.entries(schema).map(([k, v]) => ({
        [k]: 'value' in v ? v.value : 'checked' in v ? v.checked : null,
      }))
    )
  );

  /**
   * Generate a localized version of the label for the given
   * label key.  All elements in the form share this function.
   * Different labels may use different 'components' and 'values'.
   * Or a label may not use any 'components' or 'values'.
   *
   * @param {string} label Translation key for the current label
   * @returns Trans object that will be used to display the localized label.
   */
  const getLabelTranslation = (label) => {
    return (
      <Trans
        i18nKey={label}
        values={{
          programName: site.siteMetadata.programName,
        }}
        components={{
          dePrivacy: (
            <OutboundLink to="https://www.discoveryeducation.com/privacy-policy/"></OutboundLink>
          ),
          partnerPrivacy: (
            <OutboundLink
              to={site.siteMetadata.partnerPrivacyUrl}
            ></OutboundLink>
          ),
        }}
      />
    );
  };

  const handleFieldChange = (name, value) => {
    setFieldValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!validateFields()) return;

    const formId = site.siteMetadata.subscribeFormId;

    if (formId) {
      axios.post(
        `https://cepdata.discoveryeducation.com/api/v2/form`,
        {
          property_id: formId,
          fields: fieldValues,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    }

    setIsComplete(true);
  };

  const getErrorMessage = (name, value) => {
    const { label, required, validation } = schema[name];

    if (
      required &&
      ((typeof schema[name].value && value === '') ||
        (Array.isArray(schema[name].value) && value.length === 0) ||
        (schema[name].value.constructor === Object &&
          Object.entries(value).length === 0))
    ) {
      return (
        <Trans
          i18nKey={'subscribe:isRequired'}
          values={{ fieldLabel: t(label) }}
        />
      );
    } else if (validation && !validation.test(value)) {
      return validation.message;
    }

    return false;
  };

  const validateFields = () => {
    const errors = {};

    Object.entries(fieldValues).forEach(([name, value]) => {
      const error = getErrorMessage(name, value);

      if (error) {
        errors[name] = error;
      }
    });

    setErrors(errors);

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  const renderThankYou = () => (
    <>
      <h2 className="h3">{t('thankYourForInterest')}</h2>
      <p>{t('youWillBeNotified')}</p>
    </>
  );

  const renderField = (name) => {
    const { component, validation, ...attributes } = schema[name];
    const attributesWithoutLabel = { ...attributes };
    const label = attributesWithoutLabel.label;
    delete attributesWithoutLabel.label;
    const FieldComponent = component;

    if ('value' in attributes) {
      attributesWithoutLabel.value = fieldValues[name];
    } else if ('checked' in attributes) {
      attributesWithoutLabel.checked = fieldValues[name];
    }

    return (
      <FieldComponent
        name={name}
        onChange={handleFieldChange}
        error={errors[name] ? errors[name] : undefined}
        label={label ? getLabelTranslation(label) : null}
        {...attributesWithoutLabel}
      />
    );
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      {renderField('email')}
      <Row>
        <Column size={6}>
          <h3 className="subscribe__heading">{t('gradeBand')}</h3>
          {renderField('inGradesKto3')}
          {renderField('inGrades4to5')}
          {renderField('inGrades6to8')}
          {renderField('inGrades9to12')}
        </Column>
        <Column size={6}>
          <h3 className="subscribe__heading">{t('interests')}</h3>
          {renderField('interestedInSTEM')}
          {renderField('interestedInLanguageArts')}
          {renderField('interestedInEducation')}
          {renderField('interestedInVirtualFieldTrips')}
          {renderField('interestedInSweepstakesAndChallenges')}
        </Column>
      </Row>
      {renderField('subscribeToDE')}
      {renderField('subscribeToPartner')}
      {Object.keys(errors).length > 0 && (
        <Notice error>{t('reviewForm')}</Notice>
      )}
      <SubmitButton>{t('submit')}</SubmitButton>
    </Form>
  );

  return (
    <Modal trigger={trigger} title="Sign Up for Updates">
      <div className={classNames('subscribe', className)} {...other}>
        {isComplete ? renderThankYou() : renderForm()}
      </div>
    </Modal>
  );
};

Subscribe.propTypes = {
  /** The element that triggers the modal to open when clicked */
  trigger: PropTypes.node.isRequired,
  /** A custom class name */
  className: PropTypes.string,
};

export default Subscribe;
